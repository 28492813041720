<template>
  <div v-if="visible" class="modal-overlay z-20" @click="close">
    <div class="modal-content" @click.stop>
      <div class="modal-body">
        <slot name="body">Default Body</slot>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    visible: {
      type: Boolean,
      default: false
    }
  },
  methods: {
    close () {
      this.$emit('close')
    }
  }
}
</script>

<style>
.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
}

.modal-content {
  background: white;
  padding: 20px;
  border-radius: 5px;
  width: 100%;
  max-width: 600px;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
}

.modal-header {
  font-size: 1.25em;
  margin-bottom: 10px;
}

.modal-body {
  font-size: 1em;
  margin-bottom: 10px;
}

ul {
  list-style: none;
  padding: 0;
}

li {
  position: relative;
  padding-bottom: 15px;
}

li:not(:last-child)::after {
  content: '';
  position: absolute;
  left: 0;
  bottom: 0;
  width: 100%;
  height: 1px;
  background-color: #ccc;
}
</style>
